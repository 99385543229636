<template>
    <div class="cai-wrapper">
        <div :id="`doll_${uniqueId}`" class="cai-doll">
            <Head1
                :id="`head_${uniqueId}`"
                class="cai-head"
                :class="{
                    'cai-head__with-color': customAvatar?.bodyColor,
                }"
            />
            <component
                :is="fullFaceComponent"
                :id="`full-face_${fullFace}_${uniqueId}`"
                class="cai-full-face"
            />
            <component
                :is="headgearComponent"
                :id="`headgear_${headgear}_${uniqueId}`"
                class="cai-headgear"
            />
            <component
                :is="hairFrontComponent"
                :id="`hair-front_${hair}_${uniqueId}`"
                class="cai-hair"
                :class="{
                    'cai-hair__with-color': customAvatar?.hairColor,
                }"
            />
            <component
                :is="hairBackComponent"
                :id="`hair-back_${hair}_${uniqueId}`"
                class="cai-hair-back"
                :class="{
                    'cai-hair-back__with-color': customAvatar?.hairColor,
                }"
            />
            <component
                :is="browsComponent"
                :id="`brows_${brows}_${uniqueId}`"
                class="cai-brows"
            />
            <component
                :is="eyesComponent"
                :id="`eyes_${eyes}_${uniqueId}`"
                class="cai-eyes"
            />
            <component
                :is="eyewearComponent"
                :id="`eyewear_${eyewear}_${uniqueId}`"
                class="cai-eyewear"
            />
            <component
                :is="mouthComponent"
                :id="`mouth_${mouth}_${uniqueId}`"
                class="cai-mouth"
            />
            <component
                :is="bodyComponent"
                :id="`body_${body}_${uniqueId}`"
                class="cai-body"
                :class="{
                    'cai-body__with-color': customAvatar?.bodyColor,
                }"
            />
        </div>
    </div>
</template>
<script>
import {
    CustomAvatarImports,
    FULL_FACE,
    HEADGEAR,
    HAIR_FRONT,
    HAIR_BACK,
    BROWS,
    EYES,
    EYEWEAR,
    MOUTH,
    BODY,
} from '@/core/mixins/CustomAvatarImports.js';
import { mapGetters } from 'vuex';
import customAvatarJSON from '@/core/static-json/avatar/customAvatar.json';
import bodyColorsJSON from '@/core/static-json/avatar/bodyColors.json';
import hairColorsJSON from '@/core/static-json/avatar/hairColors.json';
import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export default {
    mixins: [CustomAvatarImports],
    props: {
        customAvatar: Object,
    },
    data() {
        return {
            customAvatarJSON: customAvatarJSON,
            bodyColorsJSON: bodyColorsJSON,
            hairColorsJSON: hairColorsJSON,
            uniqueId: uuidv4(),
            elementsLoaded: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'user',
        }),
        fullFace() {
            return this.customAvatar?.fullFace || null;
        },
        headgear() {
            return this.customAvatar?.headgear || null;
        },
        hair() {
            return this.customAvatar?.hair || null;
        },
        hairColor() {
            return this.customAvatar?.hairColor || null;
        },
        bodyColor() {
            return this.customAvatar?.bodyColor || 'C1A06E';
        },
        brows() {
            return this.customAvatar?.brows || null;
        },
        eyes() {
            return this.customAvatar?.eyes || null;
        },
        eyewear() {
            return this.customAvatar?.eyewear || null;
        },
        mouth() {
            return this.customAvatar?.mouth || null;
        },
        body() {
            return this.customAvatar?.body || 1;
        },
        fullFaceComponent() {
            if (this.fullFace) {
                return FULL_FACE[this.fullFace];
            }

            return null;
        },
        headgearComponent() {
            if (this.headgear) {
                return HEADGEAR[this.headgear];
            }

            return null;
        },
        hairFrontComponent() {
            if (this.hair) {
                return HAIR_FRONT[this.hair];
            }

            return null;
        },
        hairBackComponent() {
            if (this.hair) {
                return HAIR_BACK[this.hair];
            }

            return null;
        },
        browsComponent() {
            if (this.brows) {
                return BROWS[this.brows];
            }

            return null;
        },
        eyesComponent() {
            if (this.eyes) {
                return EYES[this.eyes];
            }

            return null;
        },
        eyewearComponent() {
            if (this.eyewear) {
                return EYEWEAR[this.eyewear];
            }

            return null;
        },
        mouthComponent() {
            if (this.mouth) {
                return MOUTH[this.mouth];
            }

            return null;
        },
        bodyComponent() {
            if (this.body) {
                return BODY[this.body];
            }

            return BODY[1];
        },
        headComponent() {
            return document.getElementById(`head_${this.uniqueId}`);
        },
        bodyElement() {
            if (this.body) {
                return document.getElementById(
                    `body_${this.body}_${this.uniqueId}`,
                );
            }

            return document.getElementById(`body_1_${this.uniqueId}`);
        },
        headHeadgearAnchor() {
            return this.headComponent.getElementById('anchor-headgear');
        },
        headHairAnchor() {
            return this.headComponent.getElementById('anchor-hair');
        },
        headEyesAnchor() {
            return this.headComponent.getElementById('anchor-eyes');
        },
        headMouthAnchor() {
            return this.headComponent.getElementById('anchor-mouth');
        },
        headConnectAnchor() {
            return this.headComponent.getElementById('anchor-head-connect');
        },
        bodyAnchor() {
            return document.getElementById(`anchor-body_${this.body}`);
        },
        dollComponent() {
            return document.getElementById(`doll_${this.uniqueId}`);
        },
    },
    watch: {
        customAvatar(newVal, oldVal) {
            if (newVal && !isEqual(newVal, oldVal)) {
                const diff = fromPairs(
                    differenceWith(toPairs(newVal), toPairs(oldVal), isEqual),
                );

                Object.keys(diff).forEach((key) => {
                    this.setElement(key, diff[key]);
                });
            }
        },
        elementsLoaded(newVal, oldVal) {
            if (newVal && !oldVal) {
                if (!this.customAvatar.body) {
                    this.setElement('body', 1);
                }

                Object.keys(this.customAvatar).forEach((key) => {
                    this.setElement(key, this.customAvatar[key]);
                });
            }
        },
    },
    mounted() {
        if (this.customAvatar && this.dollComponent) {
            this.checkElementsIsLoaded();
        }
    },
    methods: {
        checkElementsIsLoaded() {
            // When the avatar is inside an animation with scale,
            // there are moments when the size of all elements is 0.
            // We are waiting for at least some sizes to be able to
            // calculate the position of all elements on the avatar.
            // This does not affect situations when the avatar is static.
            // If you need more info, ask Vladyslav Kliuiev

            const dollCoords = this.dollComponent?.getBoundingClientRect();

            if (dollCoords && dollCoords.width && dollCoords.height) {
                this.elementsLoaded = true;
            } else {
                setTimeout(() => {
                    this.checkElementsIsLoaded();
                }, 150);
            }
        },
        handlePlaceFullFace(id) {
            const fullFace = document.getElementById(
                `full-face_${id}_${this.uniqueId}`,
            );

            if (!fullFace) return;

            const fullFaceAnchor = fullFace.getElementById(
                `anchor-full-face_${id}`,
            );

            if (fullFace && this.headEyesAnchor && fullFaceAnchor) {
                const headAnchorCoords =
                    this.headEyesAnchor.getBoundingClientRect();

                const headgearAnchorCoords =
                    fullFaceAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                fullFace.style.top = `${-(
                    ((headgearAnchorCoords.top - headAnchorCoords.top) /
                        dollCoords.height) *
                    100
                )}%`;

                fullFace.style.left = `${-(
                    ((headgearAnchorCoords.left - headAnchorCoords.left) /
                        dollCoords.height) *
                    100
                )}%`;

                fullFace.style.opacity = '1';
            }
        },
        handlePlaceBody(id) {
            const body = document.getElementById(`body_${id}_${this.uniqueId}`);

            if (!body) return;

            const bodyAnchor = body.getElementById(`anchor-body_${id}`);

            if (body && this.headConnectAnchor && bodyAnchor) {
                const headAnchorCoords =
                    this.headConnectAnchor.getBoundingClientRect();

                const bodyAnchorCoords = bodyAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                body.style.bottom = `${-(
                    ((headAnchorCoords.bottom - bodyAnchorCoords.bottom) /
                        dollCoords.height) *
                    100
                )}%`;

                body.style.left = `${-(
                    ((headAnchorCoords.right - bodyAnchorCoords.right) /
                        dollCoords.height) *
                        100 +
                    bodyAnchorCoords.width / 2
                )}%`;

                body.style.opacity = '1';
            }
        },
        handlePlaceHeadgear(id) {
            const headgear = document.getElementById(
                `headgear_${id}_${this.uniqueId}`,
            );
            if (!headgear) return;

            const headgearAnchor = headgear.getElementById(
                `anchor-headgear_${id}`,
            );

            if (headgear && this.headHeadgearAnchor && headgearAnchor) {
                const headAnchorCoords =
                    this.headHeadgearAnchor.getBoundingClientRect();

                const headgearAnchorCoords =
                    headgearAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                headgear.style.top = `${-(
                    ((headgearAnchorCoords.top - headAnchorCoords.top) /
                        dollCoords.height) *
                    100
                )}%`;

                headgear.style.left = `${-(
                    ((headgearAnchorCoords.left - headAnchorCoords.left) /
                        dollCoords.height) *
                    100
                )}%`;

                headgear.style.opacity = '1';
            }
        },
        handlePlaceHair(id, hasBack = false) {
            const hairFront = document.getElementById(
                `hair-front_${id}_${this.uniqueId}`,
            );

            const hairBack = document.getElementById(
                `hair-back_${id}_${this.uniqueId}`,
            );

            if (!hairFront) return;

            const hairAnchor = hairFront.getElementById(
                `anchor-hair-front_${id}`,
            );

            if (hairFront && this.headHairAnchor && hairAnchor) {
                this.$nextTick(() => {
                    const headAnchorCoords =
                        this.headHairAnchor.getBoundingClientRect();

                    const hairAnchorCoords = hairAnchor.getBoundingClientRect();

                    const dollCoords =
                        this.dollComponent.getBoundingClientRect();

                    hairFront.style.top = `${-(
                        ((hairAnchorCoords.top - headAnchorCoords.top) /
                            dollCoords.height) *
                        100
                    )}%`;

                    hairFront.style.left = `${-(
                        ((hairAnchorCoords.left - headAnchorCoords.left) /
                            dollCoords.width) *
                        100
                    )}%`;

                    if (hasBack && hairBack && this.headHeadgearAnchor) {
                        const hairBackAnchor = hairBack.getElementById(
                            `anchor-hair-back_${id}`,
                        );

                        const headHeadgearAnchorCoords =
                            this.headHeadgearAnchor.getBoundingClientRect();

                        const hairBackAnchorCoords =
                            hairBackAnchor.getBoundingClientRect();

                        hairBack.style.top = `${-(
                            ((hairBackAnchorCoords.top -
                                headHeadgearAnchorCoords.top) /
                                dollCoords.height) *
                            100
                        )}%`;

                        hairBack.style.left = `${-(
                            ((hairBackAnchorCoords.left -
                                headHeadgearAnchorCoords.left) /
                                dollCoords.width) *
                            100
                        )}px`;
                    }

                    const color = this.hairColorsJSON.find(
                        (skin) =>
                            skin.id ===
                            `hairColor_${this.customAvatar.hairColor}`,
                    )?.color;

                    this.handleChangeHairColor(color);

                    hairFront.style.opacity = '1';

                    if (hairBack) {
                        hairBack.style.opacity = '1';
                    }
                });
            }
        },
        handlePlaceBrows(id) {
            const brows = document.getElementById(
                `brows_${id}_${this.uniqueId}`,
            );
            if (!brows) return;

            const browsAnchor = brows.getElementById(`anchor-brows_${id}`);

            if (brows && this.headHairAnchor && browsAnchor) {
                const headAnchorCoords =
                    this.headHairAnchor.getBoundingClientRect();

                const browsAnchorCoords = browsAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                brows.style.top = `${-(
                    ((browsAnchorCoords.top - headAnchorCoords.top) /
                        dollCoords.height) *
                    100
                )}%`;

                brows.style.opacity = '1';
            }
        },
        handlePlaceEyes(id) {
            const eyes = document.getElementById(`eyes_${id}_${this.uniqueId}`);
            if (!eyes) return;
            const eyesAnchor = eyes.getElementById(`anchor-eyes_${id}`);

            if (eyes && this.headEyesAnchor && eyesAnchor) {
                const headAnchorCoords =
                    this.headEyesAnchor.getBoundingClientRect();

                const browsAnchorCoords = eyesAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                eyes.style.top = `${-(
                    ((browsAnchorCoords.top - headAnchorCoords.top) /
                        dollCoords.height) *
                    100
                )}%`;

                eyes.style.opacity = '1';
            }
        },
        handlePlaceEyewear(id) {
            const eyewear = document.getElementById(
                `eyewear_${id}_${this.uniqueId}`,
            );
            if (!eyewear) return;

            const eyewearAnchor = eyewear.getElementById(
                `anchor-eyewear_${id}`,
            );

            if (eyewear && this.headEyesAnchor && eyewearAnchor) {
                const headAnchorCoords =
                    this.headEyesAnchor.getBoundingClientRect();

                const browsAnchorCoords = eyewearAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                eyewear.style.top = `${-(
                    ((browsAnchorCoords.top - headAnchorCoords.top) /
                        dollCoords.height) *
                    100
                )}%`;

                eyewear.style.opacity = '1';
            }
        },
        handlePlaceMouth(id) {
            const mouth = document.getElementById(
                `mouth_${id}_${this.uniqueId}`,
            );
            if (!mouth) return;

            const mouthAnchor = mouth.getElementById(`anchor-mouth_${id}`);

            if (mouth && this.headMouthAnchor && mouthAnchor) {
                const headAnchorCoords =
                    this.headMouthAnchor.getBoundingClientRect();

                const mouthAnchorCoords = mouthAnchor.getBoundingClientRect();

                const dollCoords = this.dollComponent.getBoundingClientRect();

                mouth.style.top = `${-(
                    ((mouthAnchorCoords.top - headAnchorCoords.top) /
                        dollCoords.height) *
                    100
                )}%`;

                mouth.style.opacity = '1';
            }
        },
        handleChangeHairColor(id) {
            const hairFront = document.getElementById(
                `hair-front_${this.hair}_${this.uniqueId}`,
            );

            const hairBack = document.getElementById(
                `hair-back_${this.hair}_${this.uniqueId}`,
            );

            if (hairFront) {
                hairFront.style.setProperty('--custom-avatar-hair-color', id);
            }

            if (hairBack) {
                hairBack.style.setProperty('--custom-avatar-hair-color', id);
            }
        },
        handleChangeBodyColor(id) {
            if (this.headComponent) {
                this.headComponent.style.setProperty(
                    '--custom-avatar-body-color',
                    id,
                );
            }
            if (this.bodyElement) {
                this.bodyElement.style.setProperty(
                    '--custom-avatar-body-color',
                    id,
                );
            }
        },
        setElement(type, id) {
            const numberId = Number(id);

            if (type === 'body') {
                this.$nextTick(() => {
                    this.handlePlaceBody(numberId);

                    this.$nextTick(() => {
                        const color = this.bodyColorsJSON.find(
                            (skin) =>
                                skin.id ===
                                `bodyColor_${this.customAvatar.bodyColor}`,
                        )?.color;

                        this.handleChangeBodyColor(color);
                    });
                });
            }

            if (type === 'fullFace') {
                this.$nextTick(() => {
                    this.handlePlaceFullFace(numberId);
                });
            }

            if (type === 'headgear') {
                this.$nextTick(() => {
                    this.handlePlaceHeadgear(numberId);
                });
            }

            if (type === 'hair-front' || type === 'hair') {
                const hasBack =
                    customAvatarJSON
                        .find((item) => item.group === 'hair')
                        ?.items?.find(
                            (item) => item.id === `hair-front_${numberId}`,
                        )?.hasBack || false;

                this.$nextTick(() => {
                    this.handlePlaceHair(numberId, hasBack);
                });
            }

            if (type === 'brows') {
                this.$nextTick(() => {
                    this.handlePlaceBrows(numberId);
                });
            }

            if (type === 'eyes') {
                this.$nextTick(() => {
                    this.handlePlaceEyes(numberId);
                });
            }

            if (type === 'eyewear') {
                this.$nextTick(() => {
                    this.handlePlaceEyewear(numberId);
                });
            }

            if (type === 'mouth') {
                this.$nextTick(() => {
                    this.handlePlaceMouth(numberId);
                });
            }

            if (type === 'hairColor') {
                this.$nextTick(() => {
                    const color = this.hairColorsJSON.find(
                        (skin) =>
                            skin.id ===
                            `hairColor_${this.customAvatar.hairColor}`,
                    )?.color;

                    this.handleChangeHairColor(color);
                });
            }

            if (type === 'bodyColor') {
                this.$nextTick(() => {
                    const color = this.bodyColorsJSON.find(
                        (skin) => skin.id === `bodyColor_${id}`,
                    )?.color;

                    if (color) {
                        this.handleChangeBodyColor(color);
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.cai-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}
.cai-doll {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.cai-head {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transform: translateY(8px);
    z-index: 2;

    &__with-color {
        :deep(.bodyColor) {
            fill: var(--custom-avatar-body-color);
        }
    }
}
.cai-body-container {
    position: relative;
    display: flex;
}
.cai-body {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: 0 auto;
    z-index: 1;
    clip-path: inset(0 0 22% 0 round 0% 0% 30% 30%);

    &__with-color {
        :deep(.bodyColor) {
            fill: var(--custom-avatar-body-color) !important;
        }
    }
}
.cai-full-face {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    z-index: 4;
}
.cai-headgear {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    z-index: 4;
}
.cai-hair {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    z-index: 3;

    &__with-color {
        :deep(.hairColor) {
            fill: var(--custom-avatar-hair-color);
        }
    }
}
.cai-hair-back {
    position: absolute;
    width: 100%;
    height: auto;
    opacity: 0;
    z-index: 0;

    &__with-color {
        :deep(.hairColor) {
            fill: var(--custom-avatar-hair-color);
        }
    }
}
.cai-brows {
    position: absolute;
    width: 70%;
    height: auto;
    opacity: 0;
    z-index: 2;
}
.cai-eyes {
    position: absolute;
    width: 70%;
    height: auto;
    opacity: 0;
    z-index: 2;
}
.cai-eyewear {
    position: absolute;
    width: 114%;
    height: auto;
    opacity: 0;
    z-index: 2;
}
.cai-mouth {
    position: absolute;
    width: 70%;
    height: auto;
    opacity: 0;
    z-index: 2;
}
.cai-clothing {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 31%;
    height: auto;
    transform: translateX(-50%);
    z-index: 1;
    clip-path: inset(0 0 22% 0 round 0% 0% 6% 6%);
}
</style>
